import { Card } from "@supabase/ui";

interface Auth_Card_Props {
  children: React.ReactNode;
  title?: string;
}

export default function Auth_Card(props: Auth_Card_Props) {
  return (
    <div className="row flex-center flex justify-center my-6 dark min-w-400">
      <Card>
        <img src="./images/Logo.svg"></img>
        {props.title ? (
          <h1 className="dark:text-white font-bold text-center">
            {props.title}
          </h1>
        ) : null}
        {props.children}
      </Card>
    </div>
  );
}
