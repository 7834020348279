import { useEffect } from "react";
import { Auth as Supabase_Auth, ThemeSupa } from "@supabase/auth-ui-react";
import Auth_Card from "../../components/Auth/Auth_Card";
import { supabase } from "../../supabase_client";

export default function Auth() {
  let view = "sign_in";

  useEffect(() => {
    supabase.auth.onAuthStateChange((_event, session) => {
      switch (_event) {
        case "PASSWORD_RECOVERY":
          view = "update_password";
          break;
        default:
          view = "sign_in";
          break;
      }
    });
  }, []);

  if (view === "sign_up") {
    return (
      <Auth_Card>
        <Supabase_Auth
          redirectTo="/dashboard"
          supabaseClient={supabase}
          appearance={{ theme: ThemeSupa }}
          theme="dark"
          view={"sign_up"}
        />
      </Auth_Card>
    );
  }
  if (view === "update_password") {
    return (
      <Auth_Card>
        <Supabase_Auth
          redirectTo="/dashboard"
          supabaseClient={supabase}
          appearance={{ theme: ThemeSupa }}
          theme="dark"
          view={"update_password"}
        />
      </Auth_Card>
    );
  }

  return (
    <Auth_Card>
      <Supabase_Auth
        redirectTo="/dashboard"
        supabaseClient={supabase}
        appearance={{ theme: ThemeSupa }}
        theme="dark"
        view={"sign_in"}
      />
    </Auth_Card>
  );
}
