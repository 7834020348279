import React, { useState, useEffect } from "react";

import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Layout from "./components/Layout";
import HomeScreen from "./pages/Homepage";
import Auth from "./pages/Auth/Auth";
import Account from "./pages/Account";

import { supabase } from "./supabase_client";

export default function App() {
  const [session, set_session] = useState<any>(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      set_session(session);
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      switch (_event) {
        case "PASSWORD_RECOVERY":
          break;
        default:
          set_session(session);
          break;
      }
    });
  }, []);

  return (
    <BrowserRouter>
      {/* Routes without layout*/}
      <Routes>
        <Route path="/" element={<HomeScreen />}></Route>
      </Routes>
      {/* Routes with layout */}
      <Layout>
        <Routes>
          <Route path="/auth" element={<Auth />}></Route>
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}
