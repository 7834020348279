import Typed from "typed.js";
import { useEffect, useRef } from "react";
import { Button } from "@supabase/ui";
import { Link } from "react-router-dom";

export default function Main() {
  // Create Ref element.
  const typed_element = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    const typed = new Typed(typed_element.current as HTMLSpanElement, {
      strings: [
        `writes <span class="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600">essays</span>`,
        `creates <span class="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600">presentations</span>`,
        `paraphrases <span class="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600">articles</span>`,
        `writes <span class="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600">stories</span>`,
        `writes <span class="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600">articles</span>`,
        `writes <span class="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600">reports</span>`,
        `<span class="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600">improves</span> your <span class="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600">writing</span>`,
        `adds <span class="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600">details</span>`,
        `<span class="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600">summarizes</span> text`,
        `<span class="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600">answers</span> questions`,
        `<span class="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600">rephrases</span> sentences`,
      ],
      startDelay: 0,
      typeSpeed: 50,
      backSpeed: 40,
      backDelay: 700,
      loop: true,
    });

    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <section className="text-gray-600 body-font">
      {/* Zoomed background image to max size, if the screen size is small, start at the center of the image. Make the image not go on top of other elements */}
      <div
        className=" inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url("./images/Art1_Extended.png")`,
        }}
      >
        <div className="max-w-5xl pt-52 pb-24 mx-auto">
          <h1 className="text-80 text-center font-4 lh-6 ld-04 font-bold drop-shadow-lg  text-white mb-6">
            The future of writing
          </h1>
          <h2 className="text-2xl font-4 font-semibold lh-6 ld-04 pb-11 text-white drop-shadow-lg text-center">
            La Makina <span ref={typed_element}></span>.
          </h2>
          <div className="ml-6 text-center">
            <Button className="inline-flex items-center dark" size="xlarge">
              Request beta access
            </Button>
          </div>
          {/* Make the text visible over the image, add a gradient to the log in link */}
          <h2 className="my-6 text-xl font-4 font-semibold lh-6 ld-04 pb-11 text-gray-100 drop-shadow-lg text-center">
            Already have access?{" "}
            <Link
              className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600"
              to="/login"
            >
              Log in
            </Link>
            . .
          </h2>
        </div>
      </div>
      <div className="container flex flex-col items-center justify-center mx-auto"></div>
      <h2
        className="pt-40 mb-1 text-2xl font-semibold tracking-tighter text-center text-gray-200 lg:text-7xl md:text-6xl"
        id="features"
      >
        Features
      </h2>
      <br></br>
      <p className="mx-auto text-xl text-center text-gray-300 font-normal leading-relaxed fs521 lg:w-2/3">
        There is no shortage of things La Makina can help you with, here are
        some examples.
      </p>
      <div className="pt-12 pb-24 max-w-4xl mx-auto fsac4 md:px-1 px-3">
        <div className="ktq4">
          <img className="w-30" src="./images/Book.png"></img>
          <h3 className="pt-3 font-semibold text-lg text-white">Stories</h3>
          <p className="pt-2 value-text text-md text-gray-200 fkrr1">
            La Makina is an excellent author. It can create interesting and
            engaging stories, using ideas that no human would've come up with.
          </p>
        </div>
        <div className="ktq4">
          <img className="w-30" src="./images/Typewriter.png"></img>
          <h3 className="pt-3 font-semibold text-lg text-white">Essays</h3>
          <p className="pt-2 value-text text-md text-gray-200 fkrr1">
            La Makina can write essays on any topic. It can also help you be
            more descriptive and add details to your writing.
          </p>
        </div>
        <div className="ktq4">
          <img className="w-30" src="./images/PaperStack.png"></img>
          <h3 className="pt-3 font-semibold text-lg text-white">
            Paraphrasing, Rewriting and Summarizing
          </h3>
          <p className="pt-2 value-text text-md text-gray-200 fkrr1">
            Rewriting text manually is a thing of the past. Our Bleeding Edge AI
            is able to rewrite and improve upon any text you give it. Turn any
            boring text into something that will keep your readers engaged.
            Easily grasp concepts by summarizing the information you need,
            keeping only the most important parts.
          </p>
        </div>
        <div className="ktq4">
          <img className="w-30" src="./images/Lightbulb.png"></img>
          <h3 className="pt-3 font-semibold text-lg text-white">Ideas</h3>
          <p className="pt-2 value-text text-md text-gray-200 fkrr1">
            Out of ideas? La Makina can help you with that. It can generate
            ideas based on a subject of your choosing, or new topics altogether.
          </p>
        </div>
      </div>
      <div className="pt-32 pb-32 max-w-6xl mx-auto fsac4 md:px-1 px-3">
        <div className="ktq4">
          <img src="https://nine4.app/images/nine4-3.png"></img>
          <h3 className="pt-3 font-semibold text-lg text-white">
            Lorem ipsum dolor sit amet
          </h3>
          <p className="pt-2 value-text text-md text-gray-200 fkrr1">
            Fusce pharetra ligula mauris, quis faucibus lectus elementum vel.
            Nullam vehicula, libero at euismod tristique, neque ligula faucibus
            urna, quis ultricies massa enim in nunc. Vivamus ultricies, quam ut
            rutrum blandit, turpis massa ornare velit, in sodales tellus ex nec
            odio.
          </p>
        </div>
        <div className="ktq4">
          <img src="https://nine4.app/images/nine4-3.png"></img>
          <h3 className="pt-3 font-semibold text-lg text-white">
            Lorem ipsum dolor sit amet
          </h3>
          <p className="pt-2 value-text text-md text-gray-200 fkrr1">
            Fusce pharetra ligula mauris, quis faucibus lectus elementum vel.
            Nullam vehicula, libero at euismod tristique, neque ligula faucibus
            urna, quis ultricies massa enim in nunc. Vivamus ultricies, quam ut
            rutrum blandit, turpis massa ornare velit, in sodales tellus ex nec
            odio.
          </p>
        </div>
      </div>
      <section className="relative pb-24">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 text-center">
          <div className="py-24 md:py-36">
            <h1 className="mb-5 text-6xl font-bold text-white">
              Subscribe to our newsletter
            </h1>
            <h1 className="mb-9 text-2xl font-semibold text-gray-200">
              Enter your email address and get our newsletters straight away.
            </h1>
            <input
              type="email"
              placeholder="jack@example.com"
              name="email"
              className="border border-gray-600 w-1/4 pr-2 pl-2 py-3 mt-2 rounded-md text-gray-800 font-semibold hover:border-gray-700 bg-black"
            />{" "}
            <a
              className="inline-flex items-center px-14 py-3 mt-2 ml-2 font-medium text-black transition duration-500 ease-in-out transform bg-transparent border rounded-lg bg-white"
              href="/"
            >
              <span className="justify-center">Subscribe</span>
            </a>
          </div>
        </div>
      </section>
    </section>
  );
}
