import React from "react";
import logo from "./logo.svg";
import Header from "../components/Homepage/Header";
import Main from "../components/Homepage/Main";
import Footer from "../components/Homepage/Footer";
import "../styles/homepage.css";

function HomeScreen() {
  return (
    <div className="homepagebody">
      <Header />
      <Main />
      <Footer />
    </div>
  );
}

export default HomeScreen;
